import React from 'react';

import classes from './Spinner.css';

const spinner = (props) => (
    // <div className="d-flex">{props.t('Uploading')}<div class="spinner">
    <div className="d-flex">Uploading<div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
    <div class="bounce4"></div>
</div></div>
);

export default spinner;