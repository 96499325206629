import React, { Component } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Daterangepicker from '../Daterangepicker/Daterangepicker';

class Modal extends Component {

    state= {
        startDate: "",
        endDate: ""
    }

    onCloseHandler = (text) => {

    var mydate = " - "
       if (text == "Date"){
       var mydate= this.state.startDate + " - " + this.state.endDate
    }
       this.props.onHide(mydate);
    }
    
    onDateformateHandler(mydate) {

        var shortDate = new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'long', 
            day: '2-digit' 
          }).format(mydate);

          return shortDate;
    }
    onDateHandler = (myd) => {

        var startDate = this.onDateformateHandler(myd.selection.startDate);
        var endDate = this.onDateformateHandler(myd.selection.endDate);
        this.setState({startDate: startDate , endDate:endDate});          
                 
    }
    
    render() {
        const { t } = this.props;
        const showmodal = this.props.show;
        var classmy = "none";
        if (showmodal) {
            classmy = "flex";
        }

        return (

            <div id="mypopup" className="modalpopup" style={{ display: classmy }}>

                {/* Modal content */}
                <div className="modal-popup-content daterangepopup">
                    <div className="popup-header">
                        <h2 className="popup-title">{t('Select date range')}</h2>
                        <span className="popupclose">
                            <a onClick={() => this.onCloseHandler("clear")}><img src="./img/closepopup-icon.svg" /></a></span>
                    </div>
                    <div className="popupbody">
                        <Daterangepicker mynewdate={(myd) => this.onDateHandler(myd)} />

                        <div className="daterangepopup-bottom">
                           <div className="daterangepopup-bottom-inner"> 
                            <div className="form-element-group">
                                <input type="text" value={this.state.startDate} />
                            </div>
                            <span className="totext">{t('TO')}</span>
                            <div className="form-element-group">
                                <input type="text" value={this.state.endDate} />
                            </div>
                            <button onClick={() => this.onCloseHandler("Date")} type="" className="actionbutton primary">{t('Done')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Modal;