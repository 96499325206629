import React, { Component } from 'react';
import { authenticationService } from '../_services/authentication.service';
import { history } from '../_helpers/history';
import Input from '../components/site/UI/Input/Input';
import { Link } from 'react-router-dom';
import axios from '../axios-inventory';
import queryString from 'query-string';

 


class Reset extends Component {

  FormElement = {

    email: {
      label: this.props.t('E-MAIL'),
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: '',
  
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  }

   FormElementPasword = {

    password: {
      label: this.props.t('Password'),
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: ''
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      orderForm: this.FormElement,
      newPassForm: this.FormElementPasword,
      isNewPass: false,
      formIsValid: false,
      loading: false,
      isError: false,
      isSucess: false,
      isSucessNewPass: false,
      successData:","

    }

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
        this.props.history.push('/');
    }
}


  

  login(event) {
    event.preventDefault();

    const formData = {};
    for (let formElementIdentifier in this.state.orderForm) {
      formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
    }
    var url = "resetpassword";



    axios.post("/users/" + url, formData).then(res => {

      var fetchStatus = res.data["Status"];

      if (fetchStatus == false) {

        this.setState({ isError: true, isSucess: false, successData:"" });

      } else {


        this.setState({ isError: false, isSucess: true,successData:res.data.Data });


      }

    }).catch(err => {
      console.log(JSON.stringify(err.message));
    })

  }

  submitPass(event) {
    event.preventDefault();
    const formData = {};
    for (let formElementIdentifier in this.state.orderForm) {
      formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
    }
    let params = queryString.parse(this.props.location.search);
    // formData["token"] = "Wm5talhuTnlrNVNDdktZNmxWdHpkT0hrVTZpNXhhcXNoL2NXbXJzR1hZST0=";
    console.log(params)
    formData["token"] = params["token"];

    console.log(formData);
    axios.put("/users/updatepassword", formData).then(res => {

      console.log(res);
      var fetchStatus = res.data["Status"];
      if (fetchStatus == false) {
        this.setState({ isError: true, isSucessNewPass: false });
      } else {
        this.setState({ isError: false, isSucessNewPass: true });

      }

    }).catch(err => {
      console.log(err);

      console.log(JSON.stringify(err.message));
      this.setState({ isError: true, isSucessNewPass: false });


    })

  }

  inputChangedHandler = (event, inputIdentifire) => {
    const updatedOrderForm = {
      ...this.state.orderForm,

    }
    const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifire] = updatedFormElement;


    let formIsValid = true;
    for (let inputIdentifire in updatedOrderForm) {
      formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
    }
    this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
  }


  routeChange() {

    history.push('/login');
  }
  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.minLength && isValid
    }

    return isValid
  }

  checkQstring() {
    var pattern = /[?&]token=/;
    // let params = queryString.parse(this.props.location.search)

    var URL = this.props.location.search;
    if (pattern.test(URL)) {
      this.setState({ isNewPass: true, orderForm: this.FormElementPasword, });
    } else {
      this.setState({ isNewPass: false });

    }
  }

  componentDidMount() {
    this.checkQstring();
  }

  render() {

    const { t } = this.props;
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    //    if(this.state.isNewPass){

    //     for (let key in this.state.newPassForm) {
    //       formElementsArray.push({
    //         id: key,
    //         config: this.state.newPassForm[key]
    //       });
    //     }
    //    }else{
    //   for (let key in this.state.orderForm) {
    //     formElementsArray.push({
    //       id: key,
    //       config: this.state.orderForm[key]
    //     });
    //   }
    // }




    let form = (
      <React.Fragment>
        <div className="center-body-header">
          <img className="logo" src="./img/img_splash-screen_total-ctrl-for-food-banks_light@2x.png" />
          <h2 className="page-title">{t('Reset your password')}</h2>
          <span className="description2">{t('Fill in your e-mail address below and we’ll send you instructions to reset your password.')}</span>
        </div>

        <form className="popupform" onSubmit={(event) => this.login(event)}>

          {formElementsArray.map(formElement => (

            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              label={formElement.config.label}
              cls={this.state.isError ? "error" : null}
              errmsg={this.state.errmsg}
              changed={(event) => this.inputChangedHandler(event, formElement.id)} />

          ))}

          <div className="form-element-group">
            {this.state.isError ?
              <span class="errmsg">{t('We haven’t found any account for this e-mail address. If you’d like to set it up, please contact us on')} <a class="greenlink">{t('hello@totalctrl.no')}</a></span>
              : null
            }
          </div>




          <div class="form-element-group">
            <div class="buttonsection">
              <button type="submit" disabled={!this.state.formIsValid} class="actionbutton fullwidth primary">{t('Reset password')}</button>
            </div>
          </div>



        </form>
        <Link to="/login" className="resetpass-rtntologin greenlink">{t('Return to Login page')}</Link>
        <span className="description2">Having problems? Please contact us at <a className="greenlink">{t('hello@totalctrl.no')}</a></span>
      </React.Fragment>
    );

    let formSucess = (
      <React.Fragment>
        <div className="center-body-header">
          <img className="logo" src="./img/img_splash-screen_total-ctrl-for-food-banks_light@2x.png" />
          <h2 className="page-title">{t('You’ve got mail!')}</h2>
          <span className="description2">{t('We’ve sent you an e-mail with instructions on how to reset your password to')+" "+ this.state.successData.Email}</span>
        </div>



        <div className="form-element-group">

          <span class="">
            {t('If the e-mail hasn’t arrived, please check the spam folder in your mailbox. Still having problems? Contact us at')} <a class="greenlink">{t('help@totalctrl.no')}</a>
          </span>

        </div>




        <div class="form-element-group">
          <div class="buttonsection">
            <button onClick={this.routeChange} class="actionbutton fullwidth primary">{t('Return to Login page')}</button>
          </div>
        </div>




      </React.Fragment>
    );

    let formNewPass = (
      <React.Fragment>
        <div className="center-body-header">
          <img className="logo" src="./img/img_splash-screen_total-ctrl-for-food-banks_light@2x.png" />
          <h2 className="page-title">{t('Reset your password')}</h2>
        </div>

        <form className="popupform" onSubmit={(event) => this.submitPass(event)}>

          {formElementsArray.map(formElement => (

            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              label={formElement.config.label}
              changed={(event) => this.inputChangedHandler(event, formElement.id)} />

          ))}



<div className="form-element-group">
            {this.state.isError ?
              <span class="errmsg">{t('We haven’t found any account for this e-mail address. If you’d like to set it up, please contact us on')} <a class="greenlink">{t('hello@totalctrl.no')}</a></span>
              : null
            }
          </div>
          
          <div class="form-element-group">
            <div class="buttonsection">
              <button type="submit" disabled={!this.state.formIsValid} class="actionbutton fullwidth primary">{t('Save')}</button>
            </div>
          </div>



        </form>
        <Link to="/login" className="resetpass-rtntologin greenlink">{t('Return to Login page')}</Link>
        <span className="description2">{t('Having problems? Please contact us at')} <a className="greenlink">{t('hello@totalctrl.no')}</a></span>
      </React.Fragment>
    );

    let formNewPassSucess = (
      <React.Fragment>
        <div className="center-body-header">
          <img className="logo" src="./img/lgo-total-ctrl-for-restaurants_light@2x.png" />
          <h2 className="page-title">{t('Your password has been updated!')}  </h2>
        </div>


        <div class="form-element-group">
          <div class="buttonsection">
            <button onClick={this.routeChange} class="actionbutton fullwidth primary">{t('Return to Login page')}</button>
          </div>
        </div>




      </React.Fragment>
    );

    var selectdForm = form;
    if (this.state.isSucess) {
      selectdForm = formSucess;
    } else if (this.state.isNewPass) {


      selectdForm = formNewPass

      if (this.state.isSucessNewPass) {

        selectdForm = formNewPassSucess
      }


    } else if (this.state.isSucessNewPass) {

      selectdForm = formNewPassSucess
    }

    return (
      <div id="mypopup" className="modalpopup resetpassword">
        <div className="modal-popup-content">
          <div className="popupbody">

            {/* {this.state.isSucess? formSucess : form} */}
            {selectdForm}

          </div>
        </div>
      </div>


    );
  }
}

export default Reset;