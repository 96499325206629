import React, { Component } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRange, DateRangePicker, DefinedRange } from 'react-date-range';
import { format, addDays } from 'date-fns';


class Daterangepicker extends Component {

state = {
	dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      },
}

  handleSelect(ranges){
   
		// {
		// 	selection: {
		// 		startDate: [native Date Object],
		// 		endDate: [native Date Object],
		// 	}
		// }
	}

	 handleRangeChange(which, payload) {
    
    this.props.mynewdate(payload);

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
   

  }
	render(){

		return (
			<DateRangePicker
			  onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
		      	  showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              className={'PreviewArea'}
              months={3}
              ranges={[this.state.dateRangePicker.selection]}
              direction="horizontal"
              staticRanges={[]}
              inputRanges={[]}
              showDateDisplay={false}
              rangeColors={["#C5E8CF"]}
			/>

		)
	}
}

export default Daterangepicker;