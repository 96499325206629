import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import ModalStoreUser from '../UI/Modal/ModalStoreUser';
import axios from '../../../axios-inventory';
import ReactTooltip from 'react-tooltip';
import Modal from '../UI/Modal/Modal';
import { Link } from 'react-router-dom';
import { EventEmitter } from "../../../event";


class CustomerDetails extends Component {
    state = {
        items: Array.from({ length: 10 }),
        fetchedProduct: [],
        custDetails: [],
        hasMore: true,
        show: false,
        showCustomer: false,
        offset: 0,
        limit: 10,
        searchText: '',

    };


    getCustomerbyId() {
        axios.get(`/customer/` + this.props.match.params.id)
            .then(res => {
                
                this.setState({custDetails : res.data["Data"] });
                EventEmitter.emit('username', res.data["Data"].name);
                

            }).catch(err => {

            });
    }

    showCustomermodalHandler = (id, text, status) => {


        this.tooltipRef.tooltipRef = null;
        ReactTooltip.hide();
        this.setState({ showCustomer: true });
        console.log(id,text)
        if (text == "Add") {
            this.ModalRefCust.onModalOpenHandler()
        } else

            if (id != "0") {
                
                this.ModalRefCust.onEditHandler(id, text, status);
            }
    }

    onCustomerHideHandler = (userid, formData) => {
        console.log("Onhide", userid, formData)
        this.setState({ showCustomer: false });
        console.log("custDetails", this.state.custDetails)
        formData.staus && this.setState({custDetails: {...this.state.custDetails, status:formData.status}})
        this.getCustomerbyId();

       }

    getCustomer(searchText = '') {

        const customerId = this.props.match.params.id;

        axios.get(`/customerusers/bycustomerid/${customerId}`, {

            params: {
                searchText: searchText,
                offset: this.state.offset,
                limit: this.state.limit
            },


        }).then(res => {


            var fetchOrders = res.data["Data"];



            
            var fetchOrdersUpdated = this.state.offset != 0 ? [...this.state.fetchedProduct, ...fetchOrders] : fetchOrders




            this.setState({ fetchedProduct: fetchOrdersUpdated });


            if (res.data["Data"].length < this.state.limit) {
                this.setState({ hasMore: false });


            }



        }).catch(err => {
            this.setState({ fetchedProduct: [], hasMore: false });

        });
    }


    fetchMoreData = () => {

        if (this.state.hasMore) {

            this.setState({
                offset: this.state.offset + this.state.limit
            }, () => {
                this.getCustomer();

            });
        }
    }

    showmodalHandler = (id, text, status) => {

        if(this.tooltipRef != undefined){
        this.tooltipRef.tooltipRef = null;
        ReactTooltip.hide();
        }
        this.setState({ show: true });
        if (text == "Add") {

            this.ModalRef.onModalOpenHandler()
        } else

            if (id != "0") {
                this.ModalRef.onEditHandler(id, text, status);
            }

    }


    onHideHandler = (userid, formData) => {
        this.setState({ show: false });
        console.log("Onhide", userid, formData)
        if (formData == undefined) {
            
            this.setState({
                fetchedProduct: [],
                offset: 0,
                hasMore: true,

            }, () => {
                this.getCustomer();

            })

            return
        }


        var supIndex = this.state.fetchedProduct.findIndex(obj => obj.id == userid);

        var sups = this.state.fetchedProduct;
        let s = sups[supIndex];


        if (Object.keys(formData).length == "1") {

            s.status = formData["status"];
            
        }
        else {
            

            s.firstName = formData["FirstName"];
            s.lastName = formData["LastName"];
            s.email = formData["Email"];

        }


        sups.splice(supIndex, 1, s);


        this.setState({ fetchedProduct: sups })



    }

    componentDidMount() {

        this.getCustomer();
        this.getCustomerbyId();
    }

    onsearch(text) {

        this.setState({
            fetchedProduct: [],
            offset: 0,
            hasMore: true,

        }, () => {
            this.getCustomer(text);

        })
    }

    render() {

        const { t } = this.props;

        return (

            <div className="slim-mainpanel">
                <Modal 
                t={t}
                show={this.state.showCustomer}
                    onHide={(userid, formData) => this.onCustomerHideHandler(userid, formData)}
                    ref={ref => this.ModalRefCust = ref} />

                <ModalStoreUser 
                t={t}show={this.state.show}
                    onHide={(userid, formData) => this.onHideHandler(userid, formData)}
                    ref={ref => this.ModalRef = ref} custId={this.props.match.params.id} custUsr={true} />

                <div className="content-856">
                    <div className="table-top-section customerdetailtop">
                        <Link  to={"/customers" } className="back_icon" ><img src="./img/back_icon.png" /></Link>
                        <div className="left-sec">
                            <div className="customerinfo1">
                                <h2 className="page-title">{this.state.custDetails["name"]}</h2>
                                <span className="paragraph">({this.state.custDetails["shortCode"]})</span>
                            </div>
                            <div className="clear" />
                            <div className="customerinfo2">
                                <span className="paragraph">{(this.state.custDetails["contactEmail"])}</span>
                                {this.state.custDetails["status"] == "1" ? <img src="./img/button.png" /> : <img src="./img/Disabled.png" />}
                                {/* <span className="userstatus active">Active</span> */}
                            </div>
                        </div>
                        <div className="right-sec">

                            <span className="moreicon square" data-tip={JSON.stringify(this.state.custDetails)} data-for='resettooltip2' data-event='click focus'></span>
                            <ReactTooltip className='customtooltip' id='resettooltip2' clickable='true'
                                globalEventOff='click' place="left" effect="float" type='light' ref={ref => this.tooltipRef = ref}
                                getContent={datumAsText => {
                                    if (datumAsText == null) {
                                        return;
                                    }

                                    let d = JSON.parse(datumAsText);
                                    return (
                                        <div className="tabletooltipinner">
                                            <ul>
                                                <li ><a onClick={() => this.showCustomermodalHandler(d.id, "Edit", d.status)} onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{t('Edit Customer info')}</a></li>
                                                <li><a onClick={() => this.showCustomermodalHandler(d.id, "Disable", d.status)}
                                                    onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{d.status == "1" ? t('Disable') : t('Enable')} {t('Customer')}</a></li>
                                            </ul>
                                        </div>

                                    );
                                }}
                                offset={{ top: -60, left: -30 }}>

                            </ReactTooltip>


                        </div>
                        <img />
                    </div>
                    <div className="table-top-section">
                        <div className="left-sec">
                            <h2 className="page-title" style={{ fontWeight: "bold" }}>{t('Users')}</h2>
                        </div>
                        <div className="right-sec">
                            <form className="search-frm">
                                {/* onChange={(event) => { this.onsearch(event.target.value) }} */}
                                <input type="text" name="search-product" onChange={(event) => { this.onsearch(event.target.value) }} placeholder={t('Search Users') + "...." }/>
                            </form>
                            {/* <a className="download-btn"><img src="./img/download-btn.svg" /></a> */}
                            <button class="btn btn-success active btn-flex mg-b-10 mg-l-24 "
                                onClick={() => this.showmodalHandler("0", "Add")}
                                style={{
                                    borderRadius: "5px", letterSpacing: "0.8px", textAlign: "center", backgroundColor: "#23A956", borderColor: "#23A956", alignItems: "center",
                                    lineHeight: "17px", color: "white", marginLeft: "24px", width: "109px", height: "36px", fontSize: "12px"
                                }}> <label style={{ marginLeft: "-1px", cursor:"pointer" }}>{t(' Add User')}</label> </button>
                        </div>
                        <img />
                    </div>
                    <div className="card card-table" style={{ display: "unset" }}>
                        <div >
                            <table className="table mg-b-0 tx-13 ">
                                <thead>
                                    <tr className="tx-10">
                                        <th className="pd-y-5" style={{ width: "35%" }}>{t('FULL NAME')} <img src="./img/Arrow.svg" /></th>
                                        <th className="pd-y-5" style={{ width: "35%" }}>{t('E-MAIL')}</th>
                                        <th className="pd-y-5 " style={{ width: "20%" }}>{t('CUSTOMER APP ACCESS')}</th>
                                        <th className="pd-y-5 tx-right" style={{ width: "175px" }}></th>


                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div id="scrollableDiv" style={{ Width: '100%', overflow: 'auto', maxHeight: '600px' }}>
                            <table className="table mg-b-0 tx-13 ">
                                <InfiniteScroll
                                    dataLength={this.state.fetchedProduct.length}
                                    next={this.fetchMoreData}
                                    hasMore={this.state.hasMore}
                                    loader={<p style={{ textAlign: "center" }}>
                                        <b>{t('Loading') + "...."}</b>
                                    </p>}
                                    height={400}
                                    endMessage={
                                        <h2 style={{ textAlign: "center" }}>
                                          <br/><br/><br/>
                                        {this.state.fetchedProduct.length > 0 ? "" : t(' No result found')}
                                        </h2>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <tbody>
                                        {this.state.fetchedProduct.map(customer => (
                                            <tr className="valign-middle">
                                                <td className="valign-middle" style={{ width: "35%" }}>{customer.firstName + " "}{customer.lastName}</td>
                                                <td className="valign-middle tx-left" style={{ width: "35%" }}>{customer.email}</td>
                                                <td className="valign-middle tx-left" style={{ width: "20%" }}>{customer.status == "1" ? <img src="./img/button.png" /> : customer.status == "0" ? <img src="./img/Disabled.png" /> : <img src="./img/Pending.png" /> } </td>
                                                <td className="valign-middle tx-center" style={{ width: "175px" }}>
                                                    <a className="moreicon" data-tip={JSON.stringify(customer)}
                                                        data-for='customerTooltip' ref={(c) => { this.ref = c; }} data-event='click'>...</a>
                                                    <ReactTooltip className='customtooltip' id='customerTooltip' clickable='true'
                                                        globalEventOff='click' place="left" effect="float" type='light' ref={ref => this.tooltipRef = ref}
                                                        getContent={datumAsText => {
                                                            if (datumAsText == null) {
                                                                return;
                                                            }

                                                            let d = JSON.parse(datumAsText);
                                                            return (
                                                                <div className="tabletooltipinner">
                                                                    <ul>
                                                                        <li ><a onClick={() => this.showmodalHandler(d.id, "Edit", d.status)} onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{t('Edit user info')}</a></li>
                                                                        <li><a onClick={() => this.showmodalHandler(d.id, "Disable", d.status)}
                                                                            onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{d.status == "1" ? t('Disable') : t('Enable')} {t('customer app access')}</a></li>
                                                                    </ul>
                                                                </div>

                                                            );
                                                        }}
                                                        offset={{ top: -60, left: -30 }}>

                                                    </ReactTooltip>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </InfiniteScroll>
                            </table>
                        </div>{/* table-responsive */}
                    </div>{/* card */}

                </div>
            </div>

        );
    }
}

export default CustomerDetails;