import React, { Component } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import axios from '../../../../axios-inventory';
import { withAlert } from 'react-alert';





class Modal extends Component {


     FormElement = {
        name: {
            label: this.props.t('CUSTOMER NAME'),
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: this.props.t('Enter customer Name')
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        shortCode: {
            label: this.props.t('CUSTOMER SHORTCUT'),
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: this.props.t('Enter customer shortCode'),
    
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        email: {
            label: this.props.t('E-MAIL FOR RECEIPTS'),
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: this.props.t('Enter E-Mail'),
    
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }
    



    state = {
        orderForm: this.FormElement,
        formIsValid: false,
        loading: false,
        isEdit: false,
        isDisable: false,
        isCustomerStatus: false,
        userId: 0,
        recentId: 0
    }



    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.minLength && isValid
        }

        return isValid
    }

 

    addUserHandler(event, text) {
        event.preventDefault();
        this.setState({ loading: true });
        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }
        // this.onCloseHandler();

        if (text == "Add") {
        
                    
            

            axios.post('/customer', formData)
                .then(res => {
                    console.log("OG ADD");
                    console.log(res.data);
                    console.log(res.data["Data"].id);
                    if(res.data.Status != false){
                    this.props.alert.success(this.props.t('Customer has been added'));
                    } else {
                        this.props.alert.error(res.data.Message);

                    }
                    
                        // this.setState({recentId:res.data["Data"].id})
                        this.setState({recentId:res.data["Data"].id}, this.onCloseHandler)

                    
                })
                .catch(error => {
                    this.props.alert.error(error.message);
                    this.onCloseHandler();
                }) 
        }
        else if (text == "Edit") {


            axios.put(`/customer/${this.state.userId}`, formData)
                .then(res => {
                    if(res.data.Status != false){
                    this.props.alert.success(this.props.t('Customer Edited'));
                    }else {

                        this.props.alert.error(res.data.Message);

                    }
                    this.onCloseHandler(formData);
                })
                .catch(error => {

                    this.props.alert.error(error.message)
                    this.onCloseHandler();

                });
        }
        else if (text == this.props.t('Disable')) {
            axios.delete(`/customer/${this.state.userId}`).then(res => {
                if(res.data.Status == false){ 
                    this.props.alert.error(res.data.Message);
                }  
            }).catch(error => {
                this.props.alert.error(error.message)

                this.onCloseHandler();

            });

            var s = this.state.isCustomerStatus ? "0" : "1";

            var status = { 'status': s };
            this.onCloseHandler(status);


        }



    }


    inputChangedHandler = (event, inputIdentifire) => {
        const updatedOrderForm = {
            ...this.state.orderForm,

        }
        const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifire] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifire in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
        }
        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }

    onModalOpenHandler() {
        this.state.orderForm["name"].value = "";
        this.state.orderForm["shortCode"].value = "";
        this.state.orderForm["email"].value = "";

    }
    onCloseHandler = (formData = undefined) => {
        this.setState({
            orderForm: this.FormElement,
            formIsValid: false,
            isEdit: false,
            isDisable: false,
            isCustomerStatus: false,
        });
        if (formData != undefined) {

            if (Object.keys(formData).length == "1") {
                var newdics = formData
            } else {
                var newdics = { 'name': formData["name"], 'shortCode': formData["shortCode"], "contactEmail": formData["email"] }
            }



            this.props.onHide(this.state.userId, newdics);
        } else {
            
            this.props.onHide(this.state.userId, undefined,this.state.recentId);

        }
    }

    onCancel(e) {
        e.preventDefault();
        this.onCloseHandler();

    }

    onEditHandler(id, text, status) {

        this.setState({ userId: id })

        if (text == this.props.t('Disable')) {


            this.setState({ isDisable: true });
            if (status == "1") {
                this.setState({ isCustomerStatus: true });
            }
        } else if (text == this.props.t("Edit")) {


            this.setState({ isDisable: false, isCustomerStatus: false })
            axios.get(`/customer/${id}`, {

                params: {
                    name: '',
                    category: '',
                    offset: this.state.offset,
                    limit: this.state.limit
                },


            }).then(res => {

                var fetchedSupplier = res.data["Data"];



                this.state.orderForm["name"].value = fetchedSupplier['name'];
                if (fetchedSupplier['name'].trim() != "") {
                    this.state.orderForm["name"].valid = true
                }

                this.state.orderForm["shortCode"].value = fetchedSupplier['shortCode'];
                if (fetchedSupplier['shortCode'].trim() != "") {
                    this.state.orderForm["shortCode"].valid = true;
                }

                this.state.orderForm["email"].value = fetchedSupplier['contactEmail'];
                if (fetchedSupplier['contactEmail'].trim() != "") {
                    this.state.orderForm["email"].valid = true;
                }



                this.setState({ isEdit: true, formIsValid: true });




            }).catch(err => {
                console.log("ERROR " + err);

            })
        }
    }

    render() {

        const { t } = this.props;
        const showmodal = this.props.show;
        var classmy = "none";
        if (showmodal) {
            classmy = "flex";
        }

        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }


        let form = (
            <form className="popupform" onSubmit={(event) => this.addUserHandler(event, this.state.isEdit ? t('Edit') : t('Add'))}>

                {formElementsArray.map(formElement => (

                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />

                ))}

                <span className="noteFiled">{t('Receipts of the goods collected by customer’s team will be sent to this e-mail address')}.</span>

                {/* <Button btnType="Success" disabled={!this.state.form} >ORDER</Button> */}

                <div className="form-element-group">
                    <div className="buttonsection">
                        <button type="submit" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">{t('Cancel')}</button>
                        <button type="submit" disabled={!this.state.formIsValid} className="actionbutton primary">{(this.state.isEdit ? t('Save changes') : t('Save'))}</button>
                    </div>
                </div>
            </form>
        );

        let formDisable = (
            <form id="create-course-form" className="popupform" onSubmit={(event) => this.addUserHandler(event, "Disable")}>

                <h2 class="page-title">{this.state.isCustomerStatus ? t('Disable') : t('Enable')} {t('Customer')}</h2>

                <div>{
                    this.state.isCustomerStatus ?
                    t('If you disable a customer that no longer cooperates with you, all users from this customer will lose access to the customer application.')
                    :t('If you enable a customer, all users from this customer will gain access to the customer application.')
                 } </div>

                <div className="form-element-group">
                    <div className="buttonsection">
                        <button type="reset" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">{t('Cancel')}</button>
                        <button type="submit" className="actionbutton primary" style={this.state.isCustomerStatus ? { background: "#E1464D" } : { background: "#23A956" }}>{t('Yes')}, {this.state.isCustomerStatus ? t('Disable') : t('Enable')}</button>
                    </div>
                </div>
            </form>
        );

        return (

            <div id="mypopup" className={this.state.isDisable ? "modalpopup disablepopup" : "modalpopup"} style={{ display: classmy }}>

                {/* Modal content */}
                <div className="modal-popup-content">
                    <div className="popup-header">
                        {this.state.isDisable ? <h2 className="popup-title"></h2> : <h2 className="popup-title">{(this.state.isEdit ? t('Edit customer Info') : t('a customer'))}</h2>}
                        <span className="popupclose">
                            <a onClick={(e) => this.onCancel(e)} ><img src="./img/closepopup-icon.svg" /></a></span>
                    </div>
                    <div className="popupbody">


                        {this.state.isDisable ? formDisable : form}





                    </div>
                </div>
            </div>

        );
    }
}

export default withAlert()(Modal);