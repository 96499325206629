import React, { Component } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import axios from '../../../../axios-inventory';
import { withAlert } from 'react-alert';




class ModalOrganisation extends Component {


    FormElement = {
        name: {
            label: this.props.t('ORGANISATION NAME'),
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        email: {
            label: this.props.t('E-MAIL FOR RECEIPTS'),
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: '',
    
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }


    state = {
        orderForm: this.FormElement,
        formIsValid: false,
        storeId: 0,
        image: "",
        imageNew: "",
        isDeleteButton: false
    }


    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.minLength && isValid
        }

        return isValid
    }


    addUserHandler(event, text) {
        event.preventDefault();
        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }



        if (this.state.isDeleteButton) {
            formData["profileImage"] = this.state.imageNew;
            console.log(formData["profileImage"] );
            console.log(this.state.storeId);

        }

        axios.put(`/store/${this.state.storeId}`, formData)
            .then(res => {
                if(res.data.Status != false){
                    this.props.alert.success("Info edited successfully");

                }else{
                    this.props.alert.error(res.data.Message);                    

                }
                this.onCloseHandler(formData);
            })
            .catch(error => {
                console.log(error);
                this.props.alert.error(error.message);
                this.onCloseHandler();

            });






    }


    inputChangedHandler = (event, inputIdentifire) => {
        const updatedOrderForm = {
            ...this.state.orderForm,

        }
        const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifire] = updatedFormElement;
     

        let formIsValid = true;
        for (let inputIdentifire in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
        }
        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }


    onCloseHandler = () => {

        this.setState({
            orderForm: this.FormElement,
            formIsValid: false,
            storeId: 0,
            image: "",
            imageNew: "",
            isDeleteButton: false
        });
        this.props.onHide(this.state.userId, undefined);

        
    }

    onCancel(e) {
        e.preventDefault();
        this.onCloseHandler();

    }

    onEditHandler(storeid) {


        this.setState({ storeId: storeid })
        axios.get(`/store/${storeid}`).then(res => {

            var fetchedStoreUser = res.data["Data"];




            this.state.orderForm["name"].value = fetchedStoreUser['name'];
            if (fetchedStoreUser['name'].trim() != "") {
                this.state.orderForm["name"].valid = true
            }

            this.state.orderForm["email"].value = fetchedStoreUser['contactEmail'];
            if (fetchedStoreUser['contactEmail'].trim() != "") {
                this.state.orderForm["email"].valid = true;
            }

            var imgProfile = fetchedStoreUser["profileImage"];

            this.setState({ isEdit: true, formIsValid: true, image: imgProfile });




        }).catch(err => {
            console.log("ERROR " + err);

        })

    }

    handleChangeImage = (evt) => {
        
        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];

        this.setState({ isDeleteButton: true });

        reader.onload = function (upload) {
            self.setState({
                imageNew: upload.target.result
            });
        };
        reader.readAsDataURL(file);
        setTimeout(function () {
         
        }, 1000);

    }

    handleDeleteImage = () => {

        this.setState({ isDeleteButton: false });
    }


    render() {

        const { t } = this.props;

        const showmodal = this.props.show;
        var classmy = "none";
        if (showmodal) {
            classmy = "flex";
        }

        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }


        let form = (
            <form className="popupform" onSubmit={(event) => this.addUserHandler(event, this.state.isEdit ? t('Edit') : t('Add'))}>

                {formElementsArray.map(formElement => (

                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />

                ))}


                <span className="noteFiled">{t('This e-mail address will be used for sending purchase receipts and customer app invitations to your customers.')}</span>

                <div className="form-element-group">

                    <div className="buttonsection">
                        <button type="submit" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">{t('Cancel')}</button>
                        <button type="submit" disabled={!this.state.formIsValid} className="actionbutton primary"> {('Save changes')}</button>
                    </div>
                </div>
            </form>
        );

        return (
            <div id="mypopup" className="modalpopup" style={{ display: classmy }}>
                <div className="modal-popup-content">
                    <div className="popup-header">
                        <h2 className="popup-title">{t('Edit organisation info')}</h2>
                        <span className="popupclose">
                            <a onClick={(e) => this.onCancel(e)} ><img src="./img/closepopup-icon.svg" /></a></span>
                    </div>
                    <div className="popuptopbody organisation-popup-top">
                        <img className="profileimg" style={{ height: "96px", width: "96px" }}
                            ref={ref => this.fileInput = ref}
                            src={this.state.isDeleteButton ? this.state.imageNew : this.state.image} />
                        <span className="popuptop-title1">{t('PROFILE IMAGE')}</span>
                        <span className="paragraph">{t('For best results, upload a square image with resolution at least 600 x 600 px')} </span>
                        <div className="upload-btn-wrapper">
                            <input ref="file" type="file" name="file"
                                className="upload-file"
                                id="file"
                                onChange={(event) => this.handleChangeImage(event)}
                                onClick={e => (e.target.value = null)}
                                encType="multipart/form-data"
                                required />
                            <a className="greenlink">{this.state.isDeleteButton ? t('Change') : t('Upload')}{t('image')}</a>

                        </div>
                        <div className="upload-btn-wrapper">
                            {this.state.isDeleteButton ? <a onClick={this.handleDeleteImage} className="greenlink">{t('Delete image')}</a> : null}


                        </div>



                    </div>
                    <div className="popupbody">
                        {form}
                    </div>
                </div>
            </div>

        );
    }
}

export default withAlert() (ModalOrganisation);