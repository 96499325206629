import React, { Component } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import axios from '../../../../axios-inventory';
import { withAlert } from 'react-alert';




class ModalOrganisation extends Component {


    FormElement = {
        name: {
            label: this.props.t('FULL NAME'),
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        email: {
            label: this.props.t('E-MAIL'),
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: '',
    
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }
    state = {
        orderForm: this.FormElement,
        formIsValid: false,
        storeId: 0,
        image: "",
        imageNew: "",
        isDeleteButton: false,
        userRoleId: "",
        errmsg: false,

    }


    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.minLength && isValid
        }

        return isValid
    }


    addUserHandler(event, text) {
        event.preventDefault();
        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }

        const abc = formData["name"].split(' ');
        if(abc.length == 2  )
        {
            if(abc[1].length > 0){
        formData["firstName"] = abc[0] ? abc[0] : " ";
        formData["lastName"] = abc[1] ? abc[1] : " ";
        delete formData["name"];
        formData["userRoleId"] = this.state.userRoleId;
 

        axios.put(`/users/${this.state.storeId}`, formData)
            .then(res => {

                if(res.data.Status != false){
                    this.props.alert.success(this.props.t('Profile edited successfully'));

                 }else{
                     
                    this.props.alert.error(res.data.Message);                    
                }
                this.onCloseHandler(formData);
            })
            .catch(error => {
                console.log(error);
                this.props.alert.error(error.message);
                this.onCloseHandler();

            });
        }else{
            this.setState({ errmsg : true});
        }
        }else{
            this.setState({ errmsg : true});

        }




    }


    inputChangedHandler = (event, inputIdentifire) => {
        const updatedOrderForm = {
            ...this.state.orderForm,

        }
        const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifire] = updatedFormElement;
       

        let formIsValid = true;
        for (let inputIdentifire in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
        }
        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }


    onCloseHandler = () => {

        this.setState({
            orderForm: this.FormElement,
            formIsValid: false,
            storeId: 0,
            image: "",
            imageNew: "",
            isDeleteButton: false,
            errmsg: false,
            userRoleId :"",
        });

        this.props.onHide(this.state.userId, undefined);

        
    }

    onCancel(e) {
        e.preventDefault();
        this.onCloseHandler();

    }

    onEditHandler(storeid) {


        this.setState({ storeId: storeid })
        axios.get(`/users/${storeid}`).then(res => {

            var fetchedStoreUser = res.data["Data"];
            this.setState({userRoleId : fetchedStoreUser["userRoleId"]});

         
            this.state.orderForm["name"].value = fetchedStoreUser['firstName'] + " " + fetchedStoreUser['lastName'];
            if (fetchedStoreUser['firstName'].trim() != "") {
                this.state.orderForm["name"].valid = true
            }

            this.state.orderForm["email"].value = fetchedStoreUser['email'];
            if (fetchedStoreUser['email'].trim() != "") {
                this.state.orderForm["email"].valid = true;
            }

            var imgProfile = fetchedStoreUser["profileImage"];

            this.setState({ isEdit: true, formIsValid: true, image: imgProfile });




        }).catch(err => {
            console.log("ERROR " + err);

        })

    }

    handleChangeImage = (evt) => {
      
        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];

        this.setState({ isDeleteButton: true });

        reader.onload = function (upload) {
            self.setState({
                imageNew: upload.target.result
            });
        };
        reader.readAsDataURL(file);
        setTimeout(function () {
           
        }, 1000);

    }

    handleDeleteImage = () => {

        this.setState({ isDeleteButton: false });
    }


    render() {

        const { t } = this.props;
        const showmodal = this.props.show;
        var classmy = "none";
        if (showmodal) {
            classmy = "flex";
        }

        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }


        let form = (
            <form className="popupform" onSubmit={(event) => this.addUserHandler(event, this.state.isEdit ? t('Edit') : t('Add'))}>

                {formElementsArray.map(formElement => (

                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />

                ))}
            

                    
                <div className="form-element-group">
                {this.state.errmsg ? <span class="errmsg">{t('Please Enter FullName "eg. Joe Deo"')} </span> : ""}
                </div>

                <div className="form-element-group">

                    <div className="buttonsection">
                        <button type="submit" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">{t('Cancel')}</button>
                        <button type="submit" disabled={!this.state.formIsValid} className="actionbutton primary"> {t('Save changes')}</button>
                    </div>
                </div>

            </form>
        );

        return (
            <div id="mypopup" className="modalpopup" style={{ display: classmy }}>
                <div className="modal-popup-content">
                    <div className="popup-header">
                        <h2 className="popup-title">{t('Edit profile info')}</h2>
                        <span className="popupclose">
                            <a onClick={(e) => this.onCancel(e)} ><img src="./img/closepopup-icon.svg" /></a></span>
                    </div>
                   
                    <div className="popupbody">
                        {form}
                    </div>
                </div>
            </div>

        );
    }
}

export default withAlert() (ModalOrganisation);