import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { composeInitialProps } from 'react-i18next';

import { withTranslation } from "react-i18next";

const ExportCSV  = ({csvData, fileName,isValid, t}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    let btn = ( <React.Fragment>
        {isValid == "11" ? 
        <button  onClick={(e) => exportToCSV(csvData,fileName)}  type="button" className="actionbutton primary">{t('Download .csv file')}</button> 
        
        : 
        <button  onClick={(e) => exportToCSV(csvData,fileName)}  type="button" disabled className="actionbutton primary">{t('Download .csv file')}</button> 
        
        }
    </React.Fragment>
)
    const exportToCSV = (csvData, fileName,props) => {
   
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    return (
        <React.Fragment>
     
            {isValid != undefined ? 

                btn
            : 
                <button className="download-btn" variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}> <img src="./img/download-btn.svg" /></button>
            
            } 
            
        </React.Fragment>
    )

}

export default withTranslation("translations")(ExportCSV) ;