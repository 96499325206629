var getFirstBrowserLanguage = function () {
  var nav = window.navigator,
  browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
  i,
  language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return null;
};

 

export function getCurrentLanguage() {

  var userLang = getFirstBrowserLanguage().substring(0, 2);
  if (userLang == "no" || userLang == "nb" || userLang == "nn" || userLang == "nb-NO") {
    userLang = "no"
  } else {
    userLang = "en"
  
  }  return userLang
}

