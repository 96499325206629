import { BehaviorSubject } from 'rxjs';


import { handleResponse } from '../_helpers/handle-response';

import {url} from '../shared/basePath';




const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    setUser,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    
    const baseURL = url;

    return fetch(`${baseURL}users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    // axios.key = null;
}

function setUser(user) {
    // remove user from local storage to log user out
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}
