import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter , HashRouter} from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 4000,
    offset: '40px',
    // you can also just use 'scale'
    transition: transitions.SCALE
  }

const app = (
    
    <BrowserRouter basename="/admin" >
    <AlertProvider template={AlertTemplate} {...options}>
    <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
    </AlertProvider>
    </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
