import React, { Component } from 'react';
import { authenticationService } from '../_services/authentication.service';
import Input from '../components/site/UI/Input/Input';
import { Link } from 'react-router-dom';
import axios from '../../src/axios-inventory';






class Login extends Component {
  

     FormElement = {

        email: {
            label: this.props.t('E-MAIL'),
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: '',
                isErrorlbl: false,
                errmsg: <span class="errmsg">{this.props.t('We haven’t found any account for this e-mail address')}.
              {this.props.t('  If you’d like to set it up, please contact us on')} <a class="greenlink">{this.props.t('hello@totalctrl.no')}</a></span>,
                isPass: false
    
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false,
    
        },
        password: {
            label: this.props.t('Password'),
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: '',
                isErrorlbl: false,
                errmsg: <span class="errmsg">{this.props.t('The password you entered is incorrect.')}</span>,
                isPass: true,
                passClass: "showpass"
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }
    



    constructor(props) {
        super(props);

        this.state = {
            orderForm: this.FormElement,
            formIsValid: false,
            loading: false,
            isDisable: false,
            errmsg: "",
            isPassword: false,
            isPassHide: true

        }

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }
    }

    resetState() {
        let orderForm = this.state.orderForm;
        orderForm.email.elementConfig.isErrorlbl = false;
        this.setState({ orderForm });
        if(this.state.isPassHide == false){
        this.showPasswordToggle();
    }
    }
 
    login(event) {
        event.preventDefault();

        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }

        authenticationService.login(formData["email"], formData["password"])
            .then(
                user => {
                    
                    axios.defaults.headers["ApiKey"] =  user.ApiKey
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    // window.location.href = "/";
                    // console.log(user);
                    // axios.defaults.headers["ApiKey"] =  user.ApiKey ; 

                },
                error => {
                        
                    
                    let orderForm = this.state.orderForm;
                    if(error == this.props.t('Password not valid')){
                        orderForm.password.elementConfig.isErrorlbl = true;

                    }else if (error == this.props.t('Email not valid')){

                        orderForm.email.elementConfig.isErrorlbl = true;

                    }else {
                        orderForm.password.elementConfig.isErrorlbl = true;
                        orderForm.password.elementConfig.errmsg = <span class="errmsg">{this.props.t('Something went wrong, Please try after sometime')}.</span>;

                    }
                    this.setState({ orderForm });
                }
            );

    }

    inputChangedHandler = (event, inputIdentifire) => {
        const updatedOrderForm = {
            ...this.state.orderForm,

        }
        const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifire] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifire in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
        }
        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }

    showPasswordToggle() {

        let orderForm = this.state.orderForm;
        if (this.state.isPassHide) {
            orderForm.password.elementConfig.passClass = "";
            orderForm.password.elementConfig.type = "text";
        } else {
            orderForm.password.elementConfig.passClass = "showpass";
            orderForm.password.elementConfig.type = "password";
        }
        this.setState({ orderForm, isPassHide: !this.state.isPassHide });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.minLength && isValid
        }

        return isValid
    }

    componentWillUnmount(){
      this.resetState();
    }

    render() {

        const { t } = this.props;

        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }


        let form = (
            <form className="popupform" onSubmit={(event) => this.login(event)}>

                {formElementsArray.map((formElement) => (
                    <React.Fragment>
                        <Input
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            label={formElement.config.label}
                            toggle={(event) => this.showPasswordToggle(event)}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)} />

                    </React.Fragment>
                ))}

                <div className="form-element-group">
                    <Link to="/reset" className="greenlink">{t('Reset password')}</Link>
                    <span class="errmsg">{this.state.errmsg}</span>
                </div>




                <div class="form-element-group">
                    <div class="buttonsection">
                        <button type="submit" disabled={!this.state.formIsValid} class="actionbutton fullwidth primary">{t('Log In')}</button>
                    </div>
                </div>


            </form>
        );



        return (
            <div id="mypopup" className="modalpopup">
                <div className="modal-popup-content">
                    <div className="popupbody">
                        <div className="center-body-header">
                            <img className="logo" src="./img/img_splash-screen_total-ctrl-for-food-banks_light@2x.png" />
                            <h2 className="page-title">{t('Log in')}</h2>
                        </div>
                        {form}
                    </div>
                </div>
            </div>

        );
    }
}

export default Login;