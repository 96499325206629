import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import ExportCSV from './ExportCSV/ExportCSV ';
import axios from '../../../axios-inventory';
import ReactTooltip from 'react-tooltip';
import { getCurrentLanguage } from '../../../shared/Helpers';



class Inventory extends Component {

  customers = () => {
    let custs = []
    for (let i = 0; i <= 25; i++) {
      custs.push({
        firstName: `first${i}`, lastName: `last${i}`,
        email: `abc${i}@gmail.com`, address: `000${i} street city, ST`
      });
    }
    return custs;
  }


  state = {
    items: Array.from({ length: 20 }),
    fileName: 'Inventory',
    customers: this.customers(),
    fetchedProduct: [],
    exportResult:[],
    isMoreData: true,
    offset: 0,
    limit: 10,
    hasMore: true,
    searchText: ''

  };

  // getProducts() {
  //   let url = 'http://handstogethertech.com/totalctrlcharity/api/v2/products' + '?name=&barcode=&category=';

  //   fetch(url, {
  //     method: 'get',
  //     mode: 'no-cors',
  //     headers: new Headers({
  //       // 'ApiKey': '4fb6d71743a85b7141e04a696ec1e57381f5a20ac885ef3938e4a959f2ba',
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     })
  //   }).
  //     then(res => {
  //       console.log(res.json())
  //     }).catch(err => {
  //       console.log(err)
  //     });


  // }

  getProductExport(searchText = '') {

    axios.get(`/products`, {

      params: {
        name: searchText,
        category: '',
        isFromMatSentralenApp: 1,
        language: getCurrentLanguage()
      },


    }).then(res => {


      
      var fetchOrders = res.data["Data"];
      console.log("fetchOrders", fetchOrders)
      const fetchOrdersUpdated = [];

      for (let i = 0; i < fetchOrders.length; i++) {
        const objProduct = [];
        objProduct['ProductName'] = fetchOrders[i]['Productname'];
        objProduct['Category'] = fetchOrders[i]['productGroup'] ? fetchOrders[i]['productGroup'].name + "(" + fetchOrders[i]['productGroup'].keyword + ")" : "";
        objProduct['ParentCategory'] = fetchOrders[i]['productGroup']? fetchOrders[i]['productGroup'].parent.name : "";
        if (fetchOrders[i]['measurment'] == 0 || fetchOrders[i]['measurment'] == 3) {
          objProduct['stkPerkrt'] = "";
          objProduct['totalStk'] = "";
          if (fetchOrders[i]['measurment'] == 3)
            objProduct['Quantity'] = fetchOrders[i]['quantity'] + " kg";
          else
            objProduct['Quantity'] = fetchOrders[i]['quantity'] + " stk";

        }
        else if (fetchOrders[i]['measurment'] == 1) {
          objProduct['stkPerkrt'] = fetchOrders[i]['unitPerCarton'] + " stk i krt";
          objProduct['totalStk'] =  fetchOrders[i]['totalStk'] + " stk";
          objProduct['Quantity'] = fetchOrders[i]['quantity'] + " krt";
        }
        else if (fetchOrders[i]['measurment'] == 2) {
          objProduct['stkPerkrt'] = fetchOrders[i]['unitPerCarton'] * fetchOrders[i]['cartonPerPalette'] + " stk i krt";
          objProduct['totalStk'] =  fetchOrders[i]['totalStk'] + " stk";
          objProduct['Quantity'] = fetchOrders[i]['quantity'] * fetchOrders[i]['cartonPerPalette'] + " krt";
        }
        objProduct['expiryDate'] = fetchOrders[i]['expirationDate'];
 

        fetchOrdersUpdated.push(objProduct);
      }


     
      var fetchOrders = this.state.offset != 0 ? [...this.state.fetchedProduct, ...fetchOrdersUpdated] : fetchOrdersUpdated


      console.log(fetchOrders);

      this.setState({ exportResult: fetchOrders });


    




    }).catch(err => {
      console.log(err)
    

    });
  }

  
  getProduct(searchText = '') {

    axios.get(`/products`, {

      params: {
        name: searchText,
        category: '',
        isFromMatSentralenApp: 1,
        offset: this.state.offset,
        limit: this.state.limit,
        language: getCurrentLanguage()
      },


    }).then(res => {


      // for (let key in res.data["Data"]) {
      //   fetchOrders.push({ ...res.data["Data"][key], id: key });
      // }
      console.log(res.data["StatusCode"]);





      var fetchOrders = res.data["Data"];
      
      const fetchOrdersUpdated = [];

      for (let i = 0; i < fetchOrders.length; i++) {
        const objProduct = [];
        objProduct['Productname'] = fetchOrders[i]['Productname'];
        objProduct['Category'] = fetchOrders[i]['productGroup'] ? fetchOrders[i]['productGroup'].name + "(" + fetchOrders[i]['productGroup'].keyword + ")" : "";
        objProduct['ParentCategory'] = fetchOrders[i]['productGroup'] ? fetchOrders[i]['productGroup'].parent.name :"";
        if (fetchOrders[i]['measurment'] == 0 || fetchOrders[i]['measurment'] == 3) {
          objProduct['stkkrt'] = "";
          objProduct['totalstkkrt'] = "";
          if (fetchOrders[i]['measurment'] == 3)
            objProduct['Quantity'] = fetchOrders[i]['quantity'] + " kg";
          else
            objProduct['Quantity'] = fetchOrders[i]['quantity'] + " stk";

        }
        else if (fetchOrders[i]['measurment'] == 1) {
          objProduct['stkkrt'] = fetchOrders[i]['unitPerCarton'] + " stk i krt";
          objProduct['totalstkkrt'] = "(" + fetchOrders[i]['totalStk'] + " stk)";
          objProduct['Quantity'] = fetchOrders[i]['quantity'] + " krt";
        }
        else if (fetchOrders[i]['measurment'] == 2) {
          objProduct['stkkrt'] = fetchOrders[i]['unitPerCarton'] * fetchOrders[i]['cartonPerPalette'] + " stk i krt";
          objProduct['totalstkkrt'] = "(" + fetchOrders[i]['totalStk'] + " stk)";
          objProduct['Quantity'] = parseInt(fetchOrders[i]['quantity']) * parseInt(fetchOrders[i]['cartonPerPalette']) + " krt";
        }
        objProduct['expiryDate'] = fetchOrders[i]['expirationDate'];
        fetchOrdersUpdated.push(objProduct);
      }


     
      var fetchOrders = this.state.offset != 0 ? [...this.state.fetchedProduct, ...fetchOrdersUpdated] : fetchOrdersUpdated


      console.log(fetchOrders);

      this.setState({ fetchedProduct: fetchOrders });


      if (res.data["Data"].length < this.state.limit) {
        this.setState({ hasMore: false });
      }




    }).catch(err => {
      console.log(err)
      this.setState({  hasMore: false });

    });
  }

  componentDidMount() {
    this.getProduct();
    this.getProductExport();
  }

  fetchMoreData = () => {

    if (this.state.hasMore) {

      this.setState({
        offset: this.state.offset + this.state.limit
      }, () => {
        this.getProduct();

      });
    }

    
  };

  onsearch(text) {

    this.setState({
      fetchedProduct: [],
      offset: 0,
      // limit: 10,
      hasMore: true,

    }, () => {
      this.getProduct(text);

    })
  }


  render() {
    var { fetchedProduct } = this.state
    const { t } = this.props;

    return (
      <React.Fragment>

        <div className="slim-mainpanel">
          <div className="table-top-section">
            <div className="left-sec">
              <h2 className="page-title" style={{ fontWeight: "bold" }}>{t('Inventory')}</h2>
            </div>
            <div className="right-sec">
              <form className="search-frm" onSubmit={e => { e.preventDefault() }}>

                <input type="text" name="search-product" onChange={(event) => { this.onsearch(event.target.value) }} placeholder={t('Search products')+"...."} />
              </form>
              <ExportCSV t={this.props.t} csvData={this.state.exportResult} fileName={this.state.fileName} />
              <img />
            </div>
          </div>

          <div className="card card-table">
            <div className>
              <table className="table mg-b-0 tx-13 ">

                <thead>
                  <tr className="">
                    <th className="pd-y-5" style={{ width: "35%" }}>{t('Product Name')} <img src="./img/Arrow.svg" /></th>
                    <th className="pd-y-5 " style={{ width: "23.19%" }}>{t('Category')}</th>
                    <th className="pd-y-5 tx-right" style={{ width: "14.33%" }}>{t('Expiration Date')}</th>
                    <th className="pd-y-5 tx-right" style={{ width: "14.33%" }}>{t('Quantity')}</th>
                    <th className="pd-y-5 tx-right" style={{ width: "297px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div id="scrollableDiv" style={{ Width: '100%', overflow: 'auto', maxHeight: '600px' }}>
              <InfiniteScroll
                dataLength={fetchedProduct.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={<p style={{ textAlign: "center" }}>
                  <b>{t('Loading') + "...."}</b>
                </p>}
                endMessage={
                  <h2 style={{ textAlign: "center" }}>
                   <br/><br/><br/>
                     {this.state.fetchedProduct.length > 0 ? "" : t(' No result found')}
                  </h2>
                }
                scrollableTarget="scrollableDiv">

                <table className="table mg-b-0 tx-13 ">

                  <tbody>
                    {fetchedProduct.map(product => (
                      <tr className="">
                        <td className="pd-y-5" style={{ width: "35%" }}><b>{product.Productname}</b> <span>{product.stkkrt}</span></td>
                        <td className="pd-y-5 " style={{ width: "23.19%" }}>{product.Category}<span>{product.ParentCategory}</span></td>
                        <td className="pd-y-5 tx-right" style={{ width: "14.33%" }}>{product.expiryDate}</td>
                        <td className="pd-y-5 tx-right" style={{ width: "14.33%" }}>{product.Quantity}<span >{product.totalstkkrt}</span></td>
                        <td className="pd-y-5 tx-right" style={{ width: "40%" }}></td>
                      </tr>
                    ))}


                  </tbody>
                </table>



              </InfiniteScroll>
            </div>{/* table-responsive */}
          </div>{/* card */}
        </div>
      </React.Fragment>



    );
  }
}

export default Inventory;
