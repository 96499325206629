import React, { Component } from 'react';
import axios from '../../../axios-inventory';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import Spinner from '../UI/Spinner/Spinner';
import { Line } from 'rc-progress';
import { Link } from 'react-router-dom';


class DataExport extends Component {
    state = {
        xlData: "",
        error: "",
        isError: false,
        isLoading: false,
        isParserLoading: false,
        percent: 0,
        color: '#23A956',
    };


    importData = () => {
        console.log("Call Import")
        var file = document.querySelector("#file").value;
        var extension = file.substr(file.lastIndexOf('.') + 1).toLowerCase();
        var reg = /(.*?)\.(xls|xlsx|csv)$/;

        const chunk = (arr, size) =>
            arr
                .reduce((acc, _, i) =>
                    (i % size)
                        ? acc
                        : [...acc, arr.slice(i, i + size)]
                    , [])


        const arr = this.state.xlData.data// [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
        console.log("this.state.xlData", this.state.xlData)
        var newArr = chunk(arr, 20000);
        var rowarr = []
        newArr.map(el => {

            var abc = { data: el }
            rowarr.push(abc)
        })

        if (reg.test(file)) {
            console.log("xlData", this.state.xlData);
            this.setState({ isLoading: true, isError: false, error: "" });

            let users = [];
            let promises = [];



            this.makeRequestsFromArray(rowarr)
            return

            for (let index = 0; index < rowarr.length; index++) {


                promises.push(

                    axios.post(`/productTemplates`, rowarr[index]).then(res => {
                        console.log(res.data.Status);
                        console.log("res.data", res.data);
                        users.push(res.data);

                        // if (res.data.Status) {
                        //     this.setState({ isError: false, isLoading: false });
                        //     this.setState({ error: "Data uploaded successfully." });
                        // } else {
                        //     this.setState({ error: "There is some issue with file", isLoading: false, isError: true });

                        // }

                    }

                    ).catch(err => {
                        console.log(err)
                        this.setState({ error: "There is some issue with file", isLoading: false, isError: true });


                    })
                )
            }


            Promise.all(promises).then(() => {
                console.log("users :", users)
                console.log("rawArrayCount users : ", rowarr.length + " " + users.length)
                console.log("Condtion: ", rowarr.length == users.length ? "True Condt" : "False Condt")
                if (rowarr.length == users.length) {
                    this.setState({ isError: false, isLoading: false, error: "Data uploaded successfully." });
                }

            });



        } else {
            this.setState({ isError: true, error: "Invalid File please use valid file eg. .xlsx" })



        }

        this.setState({ xlData: "" });

    }


    makeRequestsFromArray(arr) {
        let index = 0;
        var that = this;
        function request() {
            return axios.post(`/productTemplates`, arr[index]).then((res) => {
                index++;
                let per = (index * 100) / arr.length;
                console.log("per", Math.round(per))
                that.setState({ percent: Math.round(per) })
                console.log("res", res)
                if (index >= arr.length) {
                    console.log("done ", index)
                    console.log("Final Res", res)
                    if (res.data.Status) {
                        that.setState({ isError: false, isLoading: false });
                        that.setState({ error: "Data uploaded successfully." });
                    } else {
                        that.setState({ error: "There is some issue with file", isLoading: false, isError: true, xlData: "" });

                    }
                    return 'done'
                }
                return request();
            }).catch(err => {
                console.log("err", err.message)
                console.log("err", err)
                that.setState({ error: err.message, isLoading: false, isError: true });
            });

        }
        return request();
    }

    handleImport = (evt) => {
        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];
        self.setState({ isParserLoading: true, error: "", percent: 0, xlData: '' })

        //just pass the fileObj as parameter
        ExcelRenderer(file, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                console.log(resp);

                const rows = resp.rows;
                const keys = rows[0];
                let data = [];


                for (let i = 1; i < rows.length; i++) {
                    let dataDic = {};
                    let rowData = rows[i];
                    for (let j = 0; j < keys.length; j++) {
                        if (rowData[j] == null || rowData[j] == undefined) {
                            dataDic[keys[j]] = "";
                        }
                        else {
                            dataDic[keys[j]] = rowData[j];
                        }
                    }
                    data.push(dataDic);
                }

                // this.importData("data:" + JSON.stringify(data));

                let newdic = { "data": data }
                this.setState({
                    xlData: newdic,
                    isParserLoading: false
                })


            }
        });

    }

    render() {

        const { t } = this.props;
        const { percent, color, isParserLoading } = this.state;
        let orderSummary = null;
        if (this.state.isLoading) {

            orderSummary = <Spinner />;
        }

        const containerStyle = {
            width: '250px',
        };

        return (
            <React.Fragment>
                <div className="slim-mainpanel">
                    <div className="table-top-section">
                        <div className="left-sec">
                            <h2 className="page-title">{t('Data Import')}</h2>

                        </div>
                        <div className="right-sec">
                        </div>
                        <img />
                    </div>
                    <div className="customtabpanel">
                        <div id="exTab1" className>
                            <ul className="nav nav-pills">
                                <li className={this.state.activeTabInOut ? "active" : ""} onClick={() => this.onTabHandler("in")}>
                                    <a href="#1a" data-toggle="tab">{t("Import products")}</a>
                                </li>
                            </ul>

                            <div className="dataexporttab-content tab-content clearfix">
                                <div style={containerStyle}>

                                    <Link style={{ color: "#168442" , fontWeight:"600" }} to="./doc/Sample File for Product Import.xlsx" target="_blank" download >
                                        Click here to download Sample File</Link>


                                </div>

                                <div className="tab-pane active" id="1a">

                                    <form onSubmit={(e) => { e.preventDefault() }}>

                                        <div className="upload-btn-wrapper">

                                        </div>
                                        <input type="file" className="form-control" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="file"
                                            onChange={(event) => this.handleImport(event)}
                                            onClick={e => (e.target.value = null)} style={{ marginRight: "10px" }} />
                                        <button type="button" className="actionbutton primary"
                                            disabled={this.state.xlData == "" ? true : false}
                                            onClick={this.importData}>{t('Import Data')}</button>
                                        <br />
                                        <div className="form-element-group">
                                            <div className="buttonsection">

                                                {/* <button onClick={(event) => this.onDownload(event)}  type="button" className="actionbutton primary">Import .xlsx file</button> */}
                                            </div>
                                        </div>
                                    </form>
                                    <br></br>
                                    <div className="form-element-group">
                                        {isParserLoading && <h4 className="mt-3 mb-0" style={{ fontSize: "17px" }}>{t('Please wait') + "..."}</h4>
                                        }
                                        {orderSummary &&
                                            <div style={containerStyle}>
                                                <h4 className="mt-3 mb-0" style={{ fontSize: "17px" }}>{t('Progress')} {percent}%</h4>

                                                <Line percent={percent} strokeWidth="4" strokeColor={color} />
                                                {orderSummary}
                                            </div>


                                        }
                                        <span class="errmsg" style={{ color: this.state.isError ? "red" : "green" }}>


                                            {this.state.error}  </span>
                                    </div>

                                    {/* <ExportCSV  csvData={this.state.result} isValid={main  } fileName={this.state.fileName} /> */}

                                    {/* <br></br>
        <label>{noDataFoundText}</label> */}


                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        );
    }
}

export default DataExport;