import React, { Component } from 'react';
import { NavLink, Link, matchPath, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class Sidebar extends Component {
    state = {}

    checkLink() {
        var abc = "sidemenu-icon"
        if (this.props.location.pathname == "/" || this.props.location.pathname == "/inventory") {
            abc = "sidemenu-iconactive";
        }
        return abc
    }

    render() {
        const { t } = this.props;


        let subLink = this.checkLink();
        let mainLink = subLink == "sidemenu-iconactive" ? "active" : "";


        return (

            <div className="slim-sidebar">
                <ul className="nav nav-sidebar">

                    {/* <li className="sidebar-nav-item">

                        <Link to="/dataimport" className={`sidebar-nav-link ${mainLink}`}>

                            <img className={subLink} src="./img/ic_download.svg" /> Templates Import </Link>

                    </li> */}
                    <li className="sidebar-nav-item">

                        <Link to="/inventory" className={`sidebar-nav-link ${mainLink}`}>
                            <img className={subLink} src="./img/ic_menu-inventory.svg" /> {t('Inventory')}</Link>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/suppliers" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/suppliers" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_menu-suppliers.svg" />{t('Suppliers')}</NavLink>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/customers" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/customers" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_menu-customers.svg" />{t('Customers')}</NavLink>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/data" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/data" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_download.svg" />{t('Data export')}</NavLink>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/dataimport" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/dataimport" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_download.svg" />{t('Templates Import')}</NavLink>

                    </li>
                </ul>
            </div>


        );
    }
}

export default withRouter(withTranslation("translations")(Sidebar));