import React, { Component } from 'react';
import Customers from '../../site/Customers/Customers';
import Inventory from '../../site/Inventory/Inventory';
import {Route , Switch }from 'react-router-dom';


class Mainpanel extends Component {
    state = {  }
    render() {
        return (
            <div className="slim-mainpanel">
              
            </div>

            
        );
    }
}

export default Mainpanel;