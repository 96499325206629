
//dev
// export const url = "http://52.18.237.46/matsentralen/api/v2/" ;

// Prod
// export const url = "http://production.totalctrl.com/food-bank/api/v2/" ;
// export const url = "https://app.totalctrl.com/food-bank/api/v2/" ;
export const url = "https://matsentralen.totalctrl.com/api/v2/" ;

// export const url = "https://dev.totalctrl.no/matsentralen/api/v2/"




